.body {
    background-color: darkgrey;
}

.even {
    background-color: #1C324C;
    color: #FFFFFF;
    align-items: center;
    padding: 3px;
}

.odd {
    align-items: center;
    padding: 3px;
    min-height: fit-content;
}

.button {
    border: 1px solid #1C324C;
}

.tableSelectActive {
    background-color: #1C324C;
    color: #FFFFFF;
    padding: 5px;
    border: 1px solid #1C324C;
    border-radius: 4px;
}

.tableSelectInActive {
    padding: 5px;
    border: 1px solid #1C324C;
    border-radius: 4px;
    cursor: pointer;
}

.tableSelectInActive:hover {
    background-color: #1C324C;
    color: #FFFFFF;
}

.answerYes {
    background:  #70E29A 0% 0% no-repeat padding-box;
    margin: 5px;
    padding: 5px;
}

.answerNo {
    background: #E27070 0% 0% no-repeat padding-box;
    margin: 5px;
    padding: 5px;
}

.answerNoResponseYet {
    background: #E2CB70 0% 0% no-repeat padding-box;
    margin: 5px;
    padding: 5px;
}

.answerAbstain {
    background: #70D6E2 0% 0% no-repeat padding-box;
    margin: 5px;
    padding: 5px;
}

.topRow {
    border: 1px solid grey;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 3px;
}

.topRow:hover {
    background-color: lightgrey;
}

.iconButton {
    cursor: pointer;
}

.successMessage {
    background: #0fff00;
    border: 1px solid grey;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 3px;
}